/* @import url('./sofiapro-light.otf');

@font-face {
  font-family: myFirstFont;
  src: url(../fonts/sofiapro-light.otf);
} */
@font-face {
  font-family: sofia;
  src: url('../fonts/sofia_regular.otf');
}
html,body{
  font-family: sofia !important;
}

input[type="tel"]::placeholder {

  /* Firefox, Chrome, Opera */
  text-align: center;
  font-style: italic;
}

input[type="text"]::placeholder {

  /* Firefox, Chrome, Opera */
  text-align: left;
  font-style: italic;
}

input[type="text"]:-ms-input-placeholder {

  /* Internet Explorer 10-11 */
  text-align: left !important;
  font-style: italic;

}
.checkboxChecked {
    left: -3px;
    background: #666666;
    border: 4px solid #C4C4C4;
    box-shadow: 0px 0px 0px 2px #c4c4c4; 
    margin-top: 3px;
}
.all-fields{
  display: flex;
  flex-direction: column;
}
.first-field{
  order:1;
  -webkit-order: 1;
}
.second-field{
  order:2;
  -webkit-order: 2;
}
.third-field{
  order:3;
  -webkit-order: 3;
}
.forth-field{
  order:4;
  -webkit-order: 4;
}
.fifth0-field{
  order:5;
  -webkit-order: 5;
}
.logo{
  margin-top: 7%;
}

.default-btn{
  background-color: #D63158;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(214, 49, 88, 0.14);
    letter-spacing: 1px;
    font-size: 17px;
    width: 100%;
}
.default-btn:focus{
  outline: none;
}
.custom-cont{
  max-width: 991px !important;
}
.custom-col{
  text-align: left;
}
.field-div{
  margin-top: 30px;
}
input:focus{
    outline: none;
}
.label{
  display: block;
    margin-bottom: 10px;

}
.default-input{
  width: 300px;
height: 44px;
border-radius: 4px;
border: 1px solid #233B25;
box-shadow: 0px 2px 18px rgba(35, 59, 37, 0.08);
padding-left: 15px;
}
.card-fields{
  border-radius: 4px;
box-shadow: 0px 2px 18px rgba(35, 59, 37, 0.08);
}
.firstRow{
  margin-top: 7%;
  margin-bottom: 7%;
}
.default-tip{
  padding: 5px 9px;
  background: #D63158;
  box-shadow: 0px 4px 16px rgba(214, 49, 88, 0.14);
  border-radius: 2px;
color: #FFFFFF;
margin-right: 18px;
}

.custom-select-dropdown{
  width: 300px;
height: 44px;
border-radius: 4px;
box-shadow: 0px 2px 18px rgba(35, 59, 37, 0.08);
padding-left: 15px;
}
.default-label{
  font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 17px;
color: #232323;
text-align: left;
}

.tipSection{
  margin-top: 7%;
}

.cvc-field{
  width: 73.23px;
height: 44px;
background: #FFFFFF;
border: 1px solid #233B25;
box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.05);
border-radius: 4px;
}

.date-section{
  display: flex;
  margin-top: 7%;
}

.labels{
  display: block;
}
.cvc-sec{
  margin-left: 15px;
}

.date-field-sec{
  margin-left: 32px;
margin-right: 22px;
}

.zipCode{
  width: 133.31px;
height: 44px;
background: #FFFFFF;
border: 1px solid #233B25;
box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.05);
border-radius: 4px;
}
.zipSec{
  margin-top: 5%;
}
.spacing{
  padding-left:22px;
}
.spacing-small{
  padding-left: 8px;
}
.default-line{
  border: 0.7px solid #D63158;
transform: matrix(1, 0, 0, 1, 0, 0);
width: 270px;
}

.orderSidebar{
  background: #EEFCEF;
border-radius: 12px;
width: 360px;
margin: auto;
padding: 4% 10%;
}
.left{
  text-align: left;
}
.deal{
  margin-left: 25px;
}

.custom-text{
  font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
}
.custom-text{
  font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
color: #222222;
padding: 0 15px;
}
.customSideSec{
  margin-top: 21%;
}

.right{
  text-align: right;
}
.reorder-btn{
  padding: 5px 25px;
  border:none;
  color:white;
background: #E33636;
border-radius: 4px;
}

.reorder-btns{
  padding: 5px 25px;
  border:none;
  color:white;
background: #28B446;
border-radius: 4px;
}
.reorder-sec{
  display: flex;
padding: 11px 14px;
width: 360px;
height: 43px;
background: #FFFFFF;
margin: auto;
margin-top: 10%;
border: 1px solid #eff7f0;
box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.05);
border-radius: 4px;
}
.date{
  font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
letter-spacing: -0.24px;
color: #333333;
margin: 0px 10px;
}
.price{
  font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.24px;
color: #333333;
margin: 0px 40px;
}
.dropdown-list{
  width: 300px;
  text-align: left !important;
  background: transparent;
    border: 2px solid gray !important;
    padding: 12px !important;
}
.dropdown-toggle::after{
  float: right !important;
  margin-top: 12px !important;
}
.price-btn{
padding: 0px 8px;
color: white;
font-size: 13px;
background: #FF547C;
border-radius: 2px;
margin: 0px 12px;
}
.deal-text{
  margin-bottom: 0px;
}
.custom-order-cont{
  max-width: 424px !important;
  width: 424px !important;
}
.btn-padding-left{
  padding-left: 34px !important;
}
.btn-padding-right{
  padding-right: 34px !important;
}

.coupon{
  background: transparent;
  border: none;
}
.add-field{
  color: #D63158;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 13px;
  letter-spacing: 1px;
  margin-top: 30px;
  cursor: context-menu;
  display: none;
}
.add-btn{
  border: none;
  background-color: transparent;
  color: #D63158;
  font-size: 20px;
  padding-left: 0px;
}
.add-btn:focus{
  outline: none;
}
@media only screen and (max-width: 767px) {
  .custom-col {
    padding-left: 30% !important;
  }
  .default-line{
    width: 275px;
  }
}

@media only screen and (max-width: 597px) {
  .custom-col {
    padding-left: 24% !important;
  }
  .default-line{
    width: 300px;
  }
}

@media only screen and (max-width: 510px) {
  .custom-col {
    padding-left: 18% !important;
  }
}

@media only screen and (max-width: 438px) {
  .custom-col {
    padding-left: 14% !important;
  }
}
@media only screen and (max-width: 426px) {
  .customSideSec{
    margin-top: 10%;
  }
  .date-section{
    margin-top: 4%;
  }
  .add-field{
    margin-top: 16px;
margin-bottom: 8px;
  }
  .order-detail-btn{
    margin-bottom: 20px;
  }
  .field-div{
    margin-top: 14px;
  }
  .default-btn{
    margin-left: -12px;
  }
  .logo{
    margin-top: 12%;
  }
  .all-fields{
    flex-direction: column;
  }
  .fifth-field{
    order: 2 !important;
  }
  .sixth-field{
    order: 3 !important;
  }
  .custom-add-field{
    margin-top: 0px;
    margin-bottom: 22px;
  }

  .order-detail-btn {
    text-align: center;
    margin-right: 12%;
    margin-top: 22px;
  }
  .add-field{
    display: block;
  }
  .default-tip{
    border: 2px solid #D63158;
    background: none !important;
    color: #D63158 !important;
  }
  .orderSidebar{
    width: 350px !important;
  }
  .expiry{
    display: none;
  }
  .zipSec{
    display: none;
  }
  .default-line{
    width: 275px !important;
  }
  .date-field-sec{
    margin-left: 0px !important;
  }
  .label{
    position: absolute;
/* padding-left: 22px; */
background: white;
margin-left: 12px;
padding: 0 6px;
  }
  .default-input{
    margin-top: 14px;
  }

  .dropdown-button{
    margin-top: 14px !important;
  }
  .labels{
    z-index: 999 !important;
  }


}

@media only screen and (max-width: 393px) {
  .custom-col {
    padding-left: 11% !important;
  }
}

@media only screen and (max-width: 375px) {
  .orderSidebar {
    width: 310px !important;
    padding: 4% 8% !important;
  }
  .default-line{
    width: 240px;
  }
}

@media only screen and (max-width: 361px) {
  .orderSidebar {
    width: 314px !important;
    padding: 4% 3% !important;
  }
  .default-line{
    width: 276px !important;
  }
  .custom-col{
    padding-left: 8% !important;
  }
}

@media only screen and (max-width: 327px) {
.custom-col{
  padding-left: 5% !important;
}
.orderSidebar{
  width: 280px !important;
}
.default-line{
  width: 245px !important;
}
}
