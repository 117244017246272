::-webkit-input-placeholder {
   text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
   text-align: center;
}

::-moz-placeholder {  /* Firefox 19+ */
   text-align: center;
}

:-ms-input-placeholder {
   text-align: center;
}

.date-field{
  width: 111.01px;
height: 44px;
background: #FFFFFF;
border: 1px solid #233B25;
box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.05);
border-radius: 4px;
}
